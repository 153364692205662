<template>
  <div>
    <registration-page />
  </div>
</template>

<script>
import RegistrationPage from "../components/RegistrationPage";

export default {
  name: "Registration",

  components: {
    RegistrationPage,
  },
};
</script>
