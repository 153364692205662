<template>
  <v-app id="inspire">
    <v-container fluid fill-height>
      <v-dialog v-model="loading" fullscreen>
        <v-container
          fluid
          fill-height
          style="background-color: rgba(255, 255, 255, 0.4)"
        >
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              :size="60"
              :width="7"
              color="primary"
            >
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-alert v-show="errorAxios" class="mb-6" type="error">
              {{ $t("registration.error_connection") }}
            </v-alert>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("registration.registration_form")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  :value="username"
                  v-model="user_email"
                  prepend-icon="mdi-account"
                  name="registration"
                  :label="$t('registration.email')"
                  :rules="emailRules"
                  required
                  type="text"
                ></v-text-field>
                <v-text-field
                  name="password"
                  autocomplete="on"
                  v-model="user_password_old"
                  :value="password"
                  :label="$t('registration.enter_password_old')"
                  :append-icon="
                    value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  id="password"
                  prepend-icon="mdi-lock-outline"
                  :rules="requiredField"
                  required
                ></v-text-field>

                <v-text-field
                  name="password_new2"
                  autocomplete="on"
                  v-model="user_password_new"
                  :value="password"
                  :label="$t('registration.enter_password_new')"
                  :append-icon="
                    value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  id="password_new2"
                  prepend-icon="mdi-lock-outline"
                  :rules="requiredField"
                  required
                ></v-text-field>

                <v-text-field
                  @keyup.enter="registration()"
                  name="password_new1"
                  autocomplete="on"
                  v-model="user_password_new_test"
                  :value="password"
                  :label="$t('registration.enter_password_new_test')"
                  :append-icon="
                    value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  id="password_new1"
                  prepend-icon="mdi-lock-outline"
                  :rules="requiredField"
                  required
                ></v-text-field>

                <transition name="hint" appear>
                  <div
                    v-if="passwordValidation.errors.length > 0"
                    class="hints"
                  >
                    <h2 class="mt-8 mb-4">{{ $t("registration.hint") }}</h2>
                    <p
                      v-for="error in passwordValidation.errors"
                      v-bind:key="error"
                    >
                      {{ error }}
                    </p>
                  </div>
                </transition>
                <div class="matches" v-if="notSamePasswords">
                  <p>{{ $t("registration.password_compare") }}</p>
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  passwordsFilled &&
                  !notSamePasswords &&
                  passwordValidation.valid
                "
                color="primary"
                @click="registration()"
                >{{ $t("registration.registration_button") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>


<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  data: () => ({
    rules: [
      { message: "Je nutné zadať aspoň jedno malé písmeno.", regex: /[a-z]+/ },
      { message: "Je nutné zadať aspoň jedno veľké písmeno.", regex: /[A-Z]+/ },
      { message: "Je nutné zadať minimálne osem znakov.", regex: /.{8,}/ },
      { message: "Je nutné zadať aspoň jedno číslo.", regex: /[0-9]+/ },
    ],
    value: "",
    password: "",
    username: "",
    user_password_old: "",
    user_password_new: "",
    user_password_new_test: "",
    user_email: "",
    actualLoading: 0,
    errorAxios: false,
    loading: false,
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Nesprávny tvar",
    ],
    requiredField: [(v) => !!v || "Toto pole je potrebné vyplniť"],
  }),

  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.user_password_new_test !== this.user_password_new;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return (
        this.user_password_new_test !== "" && this.user_password_new !== ""
      );
    },
    passwordValidation() {
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.user_password_new)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors };
      } else {
        return { valid: false, errors };
      }
    },
  },

  methods: {
    ...mapMutations(["setUser", "setToken", "removeToken"]),
    loadData() {
      this.removeToken;
    },
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.push("login").catch(() => {});
      this.completeLoadingBlock();
    },
    registration() {
      this.setUser(this.username);
      let body = {
        email: this.user_email,
        oldPassword: this.user_password_old,
        newPassword: this.user_password_new,
      };
      var optionAxios = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf8",
        },
      };
      this.setLoadingBlock();
      axios
        .post(
          process.env.VUE_APP_URL + process.env.VUE_APP_CHANGE_PASSWORD,
          body,
          optionAxios
        )
        .then((response) => {
          this.completeLoadingBlock();
          this.$router.push("/login").catch(() => {});
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
  },
};
</script>

<style></style>